/* All function except trapFocus and initTrapFocus must be use with jQuery element in order to work */

export function giveFocusToNonfocusable($el) {
  if ($el.attr('tabindex')) {
    $el.focus();
  } else {
    $el.attr('tabindex', '-1').focus();
  }
}

const elementsWithTrappedFocus = [];
let trappedElementsId = 0;

export function getFocusableChildrenOf($el) {
  const $focusables = $el
    .find('[tabindex="0"], a, input, textarea, button')
    .not('[tabindex="-1"]')
    .filter(':visible');

  const $first = $focusables.first();
  if ($first.is('[type="radio"]')) {
    $el
      .find('[name="' + $first.attr('name') + '"]')
      .addClass('first-focusable')
      .data('trapped-in', trappedElementsId);
  } else {
    $first.addClass('first-focusable').data('trapped-in', trappedElementsId);
  }

  const $last = $focusables.last();
  if ($last.is('[type="radio"]')) {
    $el
      .find('[name="' + $last.attr('name') + '"]')
      .addClass('last-focusable')
      .data('trapped-in', trappedElementsId);
  } else {
    $last.addClass('last-focusable').data('trapped-in', trappedElementsId);
  }

  return $focusables;
}

export function getTrapById(trapId) {
  for (let i = elementsWithTrappedFocus.length - 1; i >= 0; i--) {
    if (trapId === elementsWithTrappedFocus[i].data('trap-id')) {
      return elementsWithTrappedFocus[i];
    }
  }

  return false;
}

export function checkFocusPosition(e) {
  const $focused = jQuery(e.target);
  let $trap;

  // Is going reverse
  if (e.shiftKey) {
    if ($focused.hasClass('first-focusable')) {
      $trap = getTrapById($focused.data('trapped-in'));
      if ($trap) {
        e.preventDefault();
        const $last = $trap.find('.last-focusable');
        if ($last.is('[type="radio"]')) {
          $last.filter(':checked').focus();
        } else {
          $last.focus();
        }
      }
    }

    // Focus is going forward
  } else if ($focused.hasClass('last-focusable')) {
    $trap = getTrapById($focused.data('trapped-in'));
    if ($trap) {
      e.preventDefault();
      const $first = $trap.find('.first-focusable');
      if ($first.is('[type="radio"]')) {
        $first.filter(':checked').focus();
      } else {
        $first.focus();
      }
    }
  }
}

export function untrapFocusInside($el) {
  const trapId = $el.data('trap-id');

  for (let i = elementsWithTrappedFocus.length - 1; i >= 0; i--) {
    if (trapId === elementsWithTrappedFocus[i].data('trap-id')) {
      // Completely remove focus information from object
      elementsWithTrappedFocus[i]
        .removeData('trap-id')
        .removeClass('focus-trap')
        .off('keydown.trap');

      // Remove element from array
      elementsWithTrappedFocus.splice(i, 1);

      // Job's done.
      break;
    }
  }
}

export function trapFocusInside($el) {
  if ($el.hasClass('focus-trap')) {
    return;
  }

  trappedElementsId++;

  $el.addClass('focus-trap');
  $el.data('trap-id', trappedElementsId);

  $el.allFocusableChildren = getFocusableChildrenOf($el);

  elementsWithTrappedFocus.push($el);

  $el.on('keydown.trap', function (e) {
    // SHIFT
    if (9 === e.keyCode) {
      checkFocusPosition(e);
    }
  });
}

export function trapFocus(e, modalId) {
  const isTabPressed = e.key === `Tab` || e.keyCode === 9;

  /**
   * If a key other than tab is pressed, ignore.
   */
  if (!isTabPressed) {
    return;
  }

  /**
   * Get the targeted modal.
   */
  const modal = document.getElementById(modalId);

  /**
   * Set what type of elements should be able to receive focus.
   */
  const focusableElements = `button:not([tabindex="-1"]), [href], input, select, [tabindex]:not([tabindex="-1"]), [role=button]`;

  /**
   * Get all focusable elements in the modal.
   */
  const firstFocusableElement = modal.querySelectorAll(focusableElements)[0];
  const focusableContent = modal.querySelectorAll(focusableElements);
  const lastFocusableElement = focusableContent[focusableContent.length - 1];

  const activeE = modal.ownerDocument.activeElement;
  if (e.shiftKey) {
    if (activeE === firstFocusableElement) {
      /**
       * If tabbing backward (shift-tab) and we're currently on the first element, focus on the last element.
       */
      lastFocusableElement.focus();
      e.preventDefault();
    }
  } else if (activeE === lastFocusableElement) {
    /**
     * If tabbing forward and we're currently on the last element, focus on the first element.
     */
    firstFocusableElement.focus();
    e.preventDefault();
  }
}

export function initTrapFocus(e, modalId) {
  return trapFocus(e, modalId);
}
