import * as Focus from './common/focus';

/* global alertSettings */
import Handlebars from 'handlebars/dist/handlebars.min.js';
import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';
import { _x } from '@wordpress/i18n';

domReady(() => {
  const alertJson =
    '/wp-content/uploads/alerts_datas/alerts_data.json?v=' +
    new Date().getTime();

  fetch(alertJson)
    .then((response) => response.json())
    .then((data) => {
      alertSettings.alerts = [];

      data.forEach((alert) => {
        if (alert.expiry_date) {
          const expiryDate = new Date(alert.expiry_date);
          const currentDate = new Date();
          if (expiryDate > currentDate) {
            alertSettings.alerts.push(alert);
            return;
          }
        }
        alertSettings.alerts.push(alert);
      });

      if (alertSettings.alerts.length > 0) {
        const alertsBannerWrapper = document.querySelector(
          '.alerts-banner-wrapper'
        );
        const alertsBar = document.querySelector('.alerts-bar');
        const expandButton = document.getElementById('expand-alerts-btn');
        const closeButton = document.getElementById('close-alerts-btn');
        const alertsDetails = document.querySelector('.alerts-details');
        const alertsGrid = document.querySelector(
          '.alerts-grid .swiper .swiper-wrapper'
        );
        const alertsOverlay = document.querySelector(
          '.alerts-details__overlay'
        );
        const body = document.querySelector('body');

        if (alertsBannerWrapper) {
          body.classList.add('has-alerts-bar');
        }

        const alertTemplate = Handlebars.compile(
          document.getElementById('alerts-template').innerHTML
        );

        function renderAlerts() {
          let count = 1;
          alertSettings.alerts.forEach((alert) => {
            const alertHtml = alertTemplate({
              ...alert,
              indicatorLabel: `Go to alert ${count}`,
            });
            alertsGrid.insertAdjacentHTML('beforeend', alertHtml);
            count++;
          });
        }

        function openAlerts() {
          alertsDetails.style.display = 'flex';
          setTimeout(() => {
            body.classList.add('banner_alerts_opened');
            alertsDetails.classList.add('show');
            expandButton.querySelector('.icon').classList.add('expanded');
            closeButton.querySelector('.icon').classList.remove('collapsed');
            setTimeout(() => {
              alertsDetails.style.opacity = '1';
              alertsDetails.setAttribute('aria-hidden', 'false');
              closeButton.focus();
              body.classList.add('modal-open');
              document.addEventListener(`keydown`, function (e) {
                Focus.initTrapFocus(e, 'alerts-details');
              });
            }, 10);
          }, 10);
        }

        function closeAlerts() {
          alertsDetails.style.opacity = '0';
          closeButton.querySelector('.icon').classList.add('collapsed');
          expandButton.querySelector('.icon').classList.remove('expanded');
          setTimeout(() => {
            body.classList.remove('banner_alerts_opened');
            alertsDetails.classList.remove('show');
            alertsDetails.style.display = 'none';
            alertsBar.style.display = 'flex';
            alertsDetails.setAttribute('aria-hidden', 'true');
            expandButton.focus();
            body.classList.remove('modal-open');
            expandButton.setAttribute('aria-expanded', 'false');
            document.removeEventListener(`keydown`, function (e) {
              Focus.initTrapFocus(e, 'alerts-details');
            });
          }, 500);
        }

        if (expandButton) {
          expandButton.addEventListener('click', function () {
            openAlerts();
            expandButton.setAttribute('aria-expanded', 'true');
          });
          expandButton.addEventListener('keypress', function (event) {
            // If the user presses the "Enter" key on the keyboard
            if (event.key === 'Enter' || event.key === ' ') {
              openAlerts();
              expandButton.setAttribute('aria-expanded', 'true');
            }
          });
        }

        if (closeButton) {
          closeButton.addEventListener('click', closeAlerts);
          closeButton.addEventListener('keypress', function (event) {
            // If the user presses the "Enter" key on the keyboard
            if (event.key === 'Enter' || event.key === ' ') {
              closeAlerts();
            }
          });
        }

        if (alertsOverlay) {
          alertsOverlay.addEventListener('click', function () {
            closeAlerts();
          });
        }

        renderAlerts();

        if (alertsBar) {
          const alertsBarSwiperArgs = {
            slidesPerView: 1,
            loop: true,
            init: false,
            pagination: {
              el: alertsBar.querySelector('.swiper-pagination'),
              clickable: true,
            },
            a11y: {
              firstSlideMessage: _x(
                'This is the first alert',
                'swiper label',
                'vtx'
              ),
              lastSlideMessage: _x(
                'This is the last alert',
                'swiper label',
                'vtx'
              ),
              prevSlideMessage: _x(
                'Go to previous alert',
                'swiper label',
                'vtx'
              ),
              nextSlideMessage: _x('Go to next alert', 'swiper label', 'vtx'),
              slideLabelMessage: _x(
                'Alert {{index}} of {{slidesLength}}',
                'swiper label',
                'vtx'
              ),
              paginationBulletMessage: _x(
                'Go to alert {{index}}',
                'swiper label',
                'vtx'
              ),
            },
          };

          if (!document.documentElement.classList.contains('no-animation')) {
            alertsBarSwiperArgs.autoplay = {
              delay: 4000,
              disableOnInteraction: false,
            };
          }

          const alertsBarSwiper = new Swiper(
            alertsBar.querySelector('.alerts-bar__carousel'),
            alertsBarSwiperArgs
          );
          alertsBarSwiper.init();

          document.addEventListener('disable-animations', function () {
            alertsBarSwiper.autoplay.stop();
          });

          document.addEventListener('enable-animations', function () {
            alertsBarSwiper.autoplay.start();
          });
        }

        const alertsCarousel = document.querySelectorAll('.alerts-grid');
        if (0 < alertsCarousel.length) {
          alertsCarousel.forEach((alertCarouselItem) => {
            new Swiper(alertCarouselItem.querySelector('.swiper'), {
              slidesPerView: 'auto',
              spaceBetween: 16,
              watchOverflow: true,
              loop: false,
              navigation: {
                prevEl: alertCarouselItem.querySelector('.swiper-button-prev'),
                nextEl: alertCarouselItem.querySelector('.swiper-button-next'),
              },
              a11y: {
                firstSlideMessage: _x(
                  'This is the first slide',
                  'swiper label',
                  'vtx'
                ),
                lastSlideMessage: _x(
                  'This is the last slide',
                  'swiper label',
                  'vtx'
                ),
                prevSlideMessage: _x(
                  'Go to previous slide',
                  'swiper label',
                  'vtx'
                ),
                nextSlideMessage: _x('Go to next slide', 'swiper label', 'vtx'),
                slideLabelMessage: _x(
                  'Slide {{index}} of {{slidesLength}}',
                  'swiper label',
                  'vtx'
                ),
                paginationBulletMessage: _x(
                  'Go to slide {{index}}',
                  'swiper label',
                  'vtx'
                ),
              },
            });
          });
        }
      }
    })
    .catch(() => {
      console.log('Aucun avis important à afficher.');
    });
});
